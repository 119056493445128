import React from "react"
import * as Style from "./IdeaFilters.module.scss"
import SingleIdea from "./SingleIdea"

const IdeaListFilter = ({ area, ideas }) => {
  const ideasClone = [...ideas]

  const filtaredIdeas = ideasClone
    .sort((a, b) => {
      const dateA = new Date(a.publishedAt)
      const dateB = new Date(b.publishedAt)
      return dateB - dateA
    })
    .filter(idea => {
      if (!area) return idea
      return idea.area_en === area
    })
    .map(idea => <SingleIdea idea={idea} />)

  console.log(area, ideas)
  return <div className={Style.ideaListWraper}>{filtaredIdeas}</div>
}

export default IdeaListFilter
