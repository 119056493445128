import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import DropDown from "../components/UI/drop-down/DropDown"
import * as Style from "./idea-list.module.scss"
import Layout from "../components/layout/Layout"
import IdeaListFilter from "../components/idea-list-filter/IdeaListFilter"

const query = graphql`
  query {
    allStrapiArea {
      nodes {
        id
        area_bg
        area_en
        post_code
      }
    }
    allStrapiIdea {
      nodes {
        id
        area_en
        area_bg
        publishedAt
        pictures {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        title
        initiative_text
      }
    }
  }
`
const initialPlaceholder = "Всички"
const IdeaList = () => {
  const data = useStaticQuery(query)
  const areaData = data?.allStrapiArea
  const ideaData = data?.allStrapiIdea?.nodes
  const [placeholder, setPlaceholder] = useState(initialPlaceholder)
  const [area, setArea] = useState(null)

  return (
    <Layout>
      <section className={`${Style.ideaListSection} main-container`}>
        <div className={Style.dropDownWrapper}>
          <DropDown
            areaData={areaData}
            placeholder={placeholder}
            setPlaceholder={setPlaceholder}
            setArea={setArea}
            hasResetPlaceholder={initialPlaceholder}
          />
        </div>
        <IdeaListFilter area={area} ideas={ideaData} />
      </section>
    </Layout>
  )
}

export default IdeaList
