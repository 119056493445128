import React from "react"
import * as Style from "./IdeaFilters.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const SingleIdea = idea => {
  return (
    <div className={Style.singleIdea}>
      <h3>{idea?.idea?.title}</h3>
      <p>{idea?.idea?.initiative_text}</p>
      {idea?.idea?.pictures && (
        <div className={Style.imageWrapper}>
          <GatsbyImage
            image={
              idea?.idea?.pictures[0]?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            alt="idea_picture"
          />
        </div>
      )}
      <p className={Style.areaText}>{`${
        idea?.idea?.area_en === "Haskovo" ? "гр." : "с."
      } ${idea?.idea?.area_bg}`}</p>
    </div>
  )
}

export default SingleIdea
